<!DOCTYPE html>
<html lang="es">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>ISTL</title>
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
    integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">

  <!-- FRAMEWORK BOOTSTRAP para el estilo de la pagina-->
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
    integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">

  <!-- Los iconos tipo Solid de Fontawesome-->

  <!-- Nuestro css-->
</head>

<body>
  <!-- barra superior-->
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link">CERTIFICADOS ISTL <span class="sr-only" aria-disabled="true">(current)</span></a>
        </li>
        <li class="nav-item">
          <button type="submit" class="btn btn-primary" (click)="pagUser()">Usuarios ISTL </button>
        </li>
        <li class="nav-item">
          <button type="submit" class="btn btn-primary" style="margin-left: 50px;" (click)="pagCertifi()">Certificados
            ISTL </button>
        </li>
        <li class="nav-item">
          <button type="button" data-toggle="modal" data-target="#image" class="btn btn-primary"
            style="margin-left: 50px;" (click)="cancel()">Agregar Imagen </button>
        </li>
      </ul>
    </div>
    <!-- boton cerrar sesion-->
    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#cerrarSesion">Cerrar Sesion</button>
  </nav>
  <div class="container">
    <div class="d-flex justify-content-center h-100">
      <div class="card">
        <div class="card-header">
          <h3>Buscar Certificado </h3>
        </div>
        <div class="card-body">
          <form class="form-signin" [formGroup]="form_validar" (ngSubmit)="getCertificado(codigo)" novalidate>
            <div class="input-group form-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-user"></i></span>
              </div>
              <!--imput de dni-->
              <input type="text" placeholder="Código" class="form-control" [(ngModel)]="Certificado.codigo"
                formControlName="codigo" required autofocus #codigo>
            </div>
            <div *ngIf='codigo.invalid && (codigo.dirty || codigo.touched)'>
              <h2 style="font-size: 15px;text-align: center;">El campo no puede estar vacio</h2>
            </div>
            <div id="alert">
              <div>
                <h5 class="alert alert-danger" style="width: 450px; height: 100;
                margin-left: auto;
                margin-right: auto;" role="alert">{{vacio}}</h5>
              </div>
            </div>

            <div class="boton" class="col-14">
              <button type="submit" class="btn btn-dark"
                style="width: 150px; height: 500; margin-left: 110px; border-radius: 15px;"
                [disabled]="!form_validar.valid"><i class="fas fa-sign-in-alt"></i> BUSCAR </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="image" tabindex="-1" role="dialog" data-backdrop="false"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Usuario</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="form_image" novalidate enctype="multipart/form-data">
            <label style="color: white;">Nombre la imagen</label>
            <input type="text" class="form-control" #nombre autofocus [(ngModel)]="Image.nombre"
              formControlName="nombre"><br>
            <input type="file" class="d-none" (change)="photoSelect($event)" #changePhoto accept=".jpg,.jpeg,.png"
              [(ngModel)]="Image.photo" formControlName="photo">
            <img [src]="photoSelected || '../../assets/noImage.png'" (click)="changePhoto.click()"
              class="img-fluid"><br>
            <div class="modal-footer">
              <div class="col">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Cancelar</button>
                <button type="submit" class="btn btn-dark" style="margin-left:20px" (click)="sendPhoto(nombre)"
                  [disabled]="!form_image.valid">Guardar</button>
              </div>
            </div>

          </form>
        </div>

      </div>
    </div>
  </div>

  <div class="modal fade" id="cerrarSesion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title " id="exampleModalLabel">Eliminar Certificado</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Esta Seguro de cerrar sesión?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-dismiss="modal">Cancelar</button>
                            <button type="submit" class="btn btn-dark" data-dismiss="modal"
                                (click)="cerrarSesion()">Si</button>
                        </div>
                    </div>
                </div>
            </div>
</body>

</html>