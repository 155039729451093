<!DOCTYPE html>
<html lang="es">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <!-- bootrap y stilos -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
    <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
</head>

<body>
    <div style="margin-left: auto;margin-right: auto;">
        <form role="form" style="margin-left: 36%;" [formGroup]="form_search">
            <div class="row mx-auto">
                <div class="col-md-3">
                    <label>Buscar Usuario:</label>
                    <input type="text" class="form-control" id="codigo" placeholder="Cédula" [(ngModel)]="oneUser.dni"
                        formControlName="dni">
                </div>
                <div class="col-md-3">
                    <button type="submit" id="buscar" style="margin-top:32px;" class="btn btn-dark"
                        (click)="getOneUser()">Buscar</button>
                </div>
            </div>
        </form>
    </div><br>

    <div class="container  table-wrapper-scroll-y my-custom-scrollbar" style="margin-left: 22%;">

        <!--Nuevo Registro-->
        <div class="row mx-auto" style="margin-block-end: auto;">
            <div class="col-md-8">
                <button mat-button class="btn btn-dark" type="button" (click)="openModal()" data-toggle="modal"
                    data-target="#updateOrSave">Nuevo
                    Registro</button>
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-8">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Identificación</th>
                            <th scope="col">Nombres</th>
                            <th scope="col">Telefono</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of oneuser" (click)="datos_usuario(user)">
                            <td>{{user.dni}}</td>
                            <td>{{user.names}}</td>
                            <td>{{user.telefono}}</td>
                            <td><button class="btn btn-dark" id="delete" type="button" data-toggle="modal"
                                    data-target="#updateOrSave">Editar</button></td>
                            <td><button type="button" data-toggle="modal" data-target="#exampleModal"
                                    class="btn btn-dark" id="delete">Eliminar</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Modal para elimiar-->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Usuario</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Esta Seguro de eliminar los Datos ?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" class="btn btn-dark" data-dismiss="modal"
                            (click)="deletePerson()">Si</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal con Registros -->
    <div class="modal fade" id="updateOrSave" tabindex="-1" role="document" data-backdrop="false"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title text-center" id="exampleModalLabel">Usuarios del Sistema</h3>
                    <h4 class="modal-subtitle" style="padding-top: 20px;">Campos obligatorios</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!--Nuevo Registro-->
                    <div>
                        <div>
                            <div>
                                <form role="form" [formGroup]="form_user" (ngSubmit)="newUser()" novalidate>
                                    <div class="row mx-auto">
                                        <input type="text" class="form-control" [(ngModel)]="User._id"
                                            formControlName="_id" disabled style="width: 550px;"><br>
                                        <br>
                                        <div class="col-md-4">
                                            <label>Cédula:</label>
                                            <input type="text" class="form-control" [(ngModel)]="User.dni"
                                                formControlName="dni" id="dni">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Nombre Completo:</label>
                                                <input type="text" class="form-control" [(ngModel)]="User.names"
                                                    formControlName="names" id="nombres">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Contraseña:</label>
                                                <input type="password" class="form-control" [(ngModel)]="User.password"
                                                    formControlName="password" id="contraseña">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mx-auto">
                                        <div class="col-md-4">
                                            <label>Genero:</label>
                                            <select name="miselect" class="form-control" [(ngModel)]="User.genero"
                                                formControlName="genero" id="genero">
                                                <option>Seleccione una opcion</option>
                                                <option value="M">Masculino</option>
                                                <option value="F">Femenino</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Telefono:</label>
                                                <input type="text" class="form-control" [(ngModel)]="User.telefono"
                                                    formControlName="telefono" id="telefono">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Rol:</label>
                                                <select name="miselect" class="form-control" [(ngModel)]="User.role"
                                                    formControlName="role">
                                                    <option>Seleccione una opcion</option>
                                                    <option [value]="role.id" *ngFor="let role of role">{{role.role}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" id="guardar" [disabled]="!form_user.valid"
                                            class="btn btn-dark">Guardar</button>
                                        <button type="button" class="btn btn-primary"
                                            data-dismiss="modal">Cancelar</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>